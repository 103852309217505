
.style-switcher {
  position: fixed;
  top: .6rem;
  right: .8rem;
  padding: .3rem;
  background-color: $lskin-bg-light;
  border: 1px solid $lskin-border-dark;
  border-radius: 1rem;
  z-index: 100;

  h5 {
    font-size: 1.2rem;
    margin-bottom: .5rem;
    color: #444444 !important;
    text-align: center;
  }

  ul {
    display: flex;
    flex-direction: column;

    &:last-of-type {
      margin-top: 1rem;
    }
  }
  li {
    padding: .2rem;
    margin: .1rem 0;
  }

  button {
    height: 2.4rem;
    width: 2.4rem;
    border: 2px solid $lskin-border-dark ;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.flash-bg-pink::before,
    &.flash-bg-blue::before,
    &.flash-bg-green::before
    {
      content: '';
      position: fixed;
      top: -40%;
      right: -40%;
  
      width: 100vw;
      height: 100vh;
      border-radius: 50%;
      pointer-events: none;

      z-index: -1;
      opacity: 0;
      transform: scale(0);
      animation: Style-Show-Anim .7s ease forwards;
    }

    &.flash-bg-pink::before{
      background: radial-gradient(
        ellipse at bottom,
        $skin-pink,
        $skin-pink,
        transparent
      );
    } 
    &.flash-bg-blue::before{
      background: radial-gradient(
        ellipse at bottom,
        $skin-blue,
        $skin-blue,
        transparent
      );
    } 
    &.flash-bg-green::before{
      background: radial-gradient(
        ellipse at bottom,
        $skin-green,
        $skin-green,
        transparent
      );
    } 
  }
}

@keyframes Style-Show-Anim {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  30% {
    opacity: .5;
  }

  100% {
    opacity: 0;
    transform: scale(3);
    display: none;
  }
}
