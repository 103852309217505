.site-container {
  font-family: $main_font;
  color: color(gray2);
  display: flex;
  flex-direction: column;
}


.limit-page {
  flex: 1;
  width: 100%;
  max-width: 160rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 5rem;
}



@import './site-footer';
@import './site-header';