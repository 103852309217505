.pink-page-style {
  $skin-style-color: $skin-pink;
  $skin-color: $skin-style-color;
  .music-container {
    box-shadow: 0 2rem 2rem 0 rgba($skin-style-color, .3);
  }
  .progress-container {
    border-color: $skin-style-color;
  }
  .progress {
    background-color: $skin-style-color;
  }

  .company-logo {
    a::before {
      border-top: .4rem solid $skin-style-color;
      border-left: .4rem solid $skin-style-color;
    }
  
    a::after {
      border-right: .4rem solid $skin-style-color;
      border-bottom: .4rem solid $skin-style-color;
    }
  }
  


  .nav__side-drawer {
    .menu-line {
      background-color: $skin-style-color;
    }
    &.opened-drawer .menu-line {
      background-color: $skin-style-color;
    }
  }

  
  .side-nav__item {
  
    &::before {
      background-color: $skin-style-color;
    }
  
    a:hover,
    a.active {
      color: $skin-style-color;
    }
  }
  
  .side-nav__toggler {
    span {
      background-color: $skin-style-color;
  
      &::after,
      &::before {
        background-color: $skin-style-color;
      }
    }
  }
  
  .page-section {
  
    .link-btn {
      background-color: $skin-style-color;
    }
  }
  
  .page-title {
    h2 {
      border-color: $skin-style-color;
      
      &::after,
      &::before {
        background-color: $skin-style-color;
      }
    }
  }
  
  
  .home {
    .social-links {
      a {
        background-color: $skin-style-color;
      }
    }
  }
  
  .about {
  
    .about-text {
      span {
        color: $skin-style-color !important;
      }
    }
  
    .skill-item {
      .progress-in {
        background-color: $skin-style-color;
      }
    }
  
    .timeline-item {
      &::before {
        background-color: $skin-style-color;
      }
  
      .circle-dot {
        background-color: $skin-style-color;
      }
    }
  }
  
  .services {
  
    .service-item {
      .icon {
  
        &::before {
          background: radial-gradient(ellipse at bottom,
              $skin-style-color,
              $skin-style-color,
              transparent);
        }
  
        svg {
          fill: $skin-style-color;
        }
      }
    }
  
    .service-item:hover {
      svg {
        fill: #ffffff;
      }
    }
  }
  
  
  .portfolio {
  
    .portfolio-filter {
  
      button.btn-target,
      button.active {
        color: $skin-style-color;
        border-color: $skin-style-color;
  
        &::before,
        &::after {
          border-color: $skin-style-color;
        }
      }
    }
  
    .portfolio-info {
      background: radial-gradient(ellipse at center,
          rgba($skin-style-color, .4) 0%,
          rgba($skin-style-color, .8) 90%);
  
  
      .icon svg {
        fill: $skin-style-color;
      }
    }
  }
  
  
  .blog {
  
    .blog-img {
      .blog-date {
        color: white;
        background-color: $skin-style-color;
      }
    }
  
    .tag-link {
      color: $skin-style-color;
    }
  }
  
  .contact {
  
    .icon {
      color: $skin-style-color;
    }
  }


  .lightbox {
    .lightbox-controls {
  
      .prev-item,
      .next-item {
        color: $skin-color;
      }
    }
  }
}

