.lightbox {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, .6);
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: zoom-out;
  padding: 3rem;
}

.lightbox-content {
  position: relative;
  font-family: $font-primary;

  img {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 75vh;
    display: block;
    cursor: pointer;

    @include no-select;
  }

  .lightbox-caption {
    position: absolute;
    bottom: -3rem;
    left: 0;

    width: 100%;
    // background-color: rgba(#000, .5);
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    color: #ffffff;
    font-weight: bold;
    
    -webkit-user-select: none;
    user-select: none;
  }

  .lightbox-close {
    color: #ffffff;
    font-size: 3rem;
    position: absolute;
    top: -40px;
    right: -1rem;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform .4s ease;

    &:hover {
      transform: rotate(90deg);
    }
  }

}

.lightbox-controls {
  .prev-item,
  .next-item {
    height: 4.5rem;
    width: 4.5rem;
    background-color: #ffffff;
    text-align: center;
    font-size: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    -webkit-tap-highlight-color: #000000;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      transform: scale(1.5);
      border-radius: 50%;
    }

    top: 50%;
    margin-top: -2.5rem;
    position: absolute;
    z-index: 100;
    
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
    &:active {
      transform: scale(1);
    }
  }

  .prev-item {
    left: 3rem;
  }

  .next-item {
    right: 3rem;
  }

}




.lightbox .lightbox-content {
  position: relative;
}

.lightbox {
  animation: fade-in .3s ease forwards;
}

.lightbox.open .lightbox-content {
  animation: lightboxImg-open 0.5s ease forwards;
}

@keyframes lightboxImg-open {
  0% {
    opacity: 0;
    transform: scale(.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}