.user-profile {
  background: #eee;
  min-height: 100%;
  padding-bottom: 6rem;

  .title,
  .subtitle {
    width: 100%;
    max-width: 80rem;
    padding-left: 1rem;
  }

  .title {
    font-size: 3rem;
  }

  .subtitle {
    font-size: 2rem;
    color: #777;
  }

  section{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
  }

  .text-content {
    font-size: 1.4rem;
    width: 100%;
    max-width: 80rem;
    padding: 1rem;
    background-color: #fff;
  }

  .header-container {
    max-width: 80rem;
    margin: 0 auto;
    position: relative;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 20rem;
  }
}



.profile-header {
  background: $site-bg-gradient-3;


  .user-imagebox {
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 3rem;
    right: 6rem;

    @include tablet {
      width: 10rem;
      height: 10rem;
      right: 2rem;
      top: 6rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center bottom;
    }
  }

  .user-namebox {
    position: absolute;
    bottom: 5rem;
    left: 1rem;
    @include mobile {
      width: 10rem;
    }
  }

  .user-name {
    color: white;
    background-color: #1eb0ee85;
    padding: .3rem 2rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    line-height: 1.2;
    text-align: center;
    display: inline;
    font-size: 4rem;
  }

  .user-titlebox {
    position: absolute;
    top: 15rem;
    left: 1rem;
  }

  .user-title {
    font-size: 2rem;
    padding: 0 1rem;
    color: #3ab7eda0;
    text-align: right;
  }
}



