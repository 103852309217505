@import url('https://fonts.googleapis.com/css?family=Inconsolata:300,400|Krub|Lato:300,400|Marvel|Montserrat+Subrayada|Poppins:300,400|Quicksand:300,400&display=swap');

/*

https://fonts.google.com/?selection.family=Inconsolata:300,400|Krub|Lato:300,400|Marvel|Montserrat+Subrayada|Poppins:300,400|Quicksand:300,400

//typewriter
font-family: 'Inconsolata', monospace;

font-family: 'Lato', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Quicksand', sans-serif;
font-family: 'Montserrat Subrayada', sans-serif;
font-family: 'Marvel', sans-serif;
*/

$font-primary: 'Quicksand', sans-serif;
$font-title:  'Poppins', sans-serif;

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Quicksand', sans-serif;
  color: #4444;
}


h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.6rem;
}

h5 {
  font-size: 1.4rem;
}

.-letter-sp-1 {
  letter-spacing: 1px;
  margin-right: -1px;
}

.-letter-sp-2 {
  letter-spacing: 2px;
  margin-right: -2px;
}


.text-xl {
  font-size: 4rem;
  @include mobile {
    font-size: 3.5rem;
  }
}

.text-lg {
  font-size: 3rem;
}
.text-md {
  font-size: 2.4rem;
}
.text-sm {
  font-size: 2rem;
}
.text-xs {
  font-size: 1.4rem;
}

.t-center {
  text-align: center;
}

.-col-gray {
  color: #999;
}

.-col-white {
  color: white;
}