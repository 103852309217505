// General Styles
body {
  line-height: 1.5;
}

// Font awesome
// .site-container {
//   padding-left: 27rem;
// }

.site-content {
  flex: 1;
}

@keyframes Page-Turn-Anim {
  0% {
    transform: translateX(100vw);
  }

  1% {
    transform: translateX(100vw);
  }

  80% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes Page-Fade-Out {
  0% {
    transform: translateX(0vw);
  }

  95% {
    transform: translateX(-100vw);
  }

  100% {
    transform: translateX(-100vw);
  }
}

html {
  overflow-x: hidden;
}

.page-section {
  background-color: $lskin-bg-dark;
  padding: 0 3rem;

  min-height: 100vh;
  display: flex;


  left: 27rem;
  top: 0;
  right: 0;


  @include mobile {
    padding-bottom: 8rem;
  }

  transform: translateX(0vw);
  position: absolute;
  box-shadow: 1px 1px 8px #0002;

  &.inactive-page {
    display: none;
    z-index: 0;
  }
  
  &.active-page {
    z-index: 2;
    animation: Page-Turn-Anim 1s ease;
  }
  &.prev-page{
    z-index: 1;
    position: fixed;
    
    

    @include tabwide {
      position: absolute;
      animation: Page-Fade-Out 1.1s ease forwards;
    }
  }
  

  


  h1,h2,h3,h4,h5,h6 {
    color: $lskin-heading;
  }

  h3 {
    font-size: 2.4rem;
  }
  h4 {
    font-size: 1.8rem;
  }

  p {
    color: $lskin-text;
    font-size: 1.6rem;
  }

  a {
    color: $lskin-text;
  }

  input, textarea, select {
    font-family: $font-title;
  }

  .container {
    padding-top: 6rem;
    padding-bottom: 7rem;
  }

  .link-btn {
    font-size: 1.6rem;
    font-family: $font-title;
    font-weight: 500;
    padding: 1rem 3rem;
    color: #ffffff;
    border-radius: 4rem;
    margin: 0 .6rem;
    transition: all 0.3s ease;
    display: inline-block;
    white-space: nowrap;
    border: none;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.page-title {
  flex: 0 1 100%;
  // max-width: 100%;
  margin-bottom: 6rem;

  h2 {
    font-size: 4rem;
    margin: 0;
    position: relative;
    border-bottom: 4px solid silver;
    display: inline-block;
    transition: all .6s ease;

    // &::after,
    // &::before {
    //   content: '';
    //   height: 4px;
    //   width: 5rem;
    //   position: absolute;
    //   top: 100%;
    //   left: 0;
    //   background-color: #999;
    // }

    // &::after {
    //   margin-top: .8rem;
    //   width: 2.5rem;
    // }
  }
}

.padd-15 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.container {
  max-width: 110rem;
  margin: auto;
  width: 100%;

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1.5rem;
    margin-left: -1.5rem;
    position: relative;
  }

  .row-grid-fill-medium {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(
      30rem, 1fr
    ));
    margin-right: -1.5rem;
    margin-left: -1.5rem;
    position: relative;
  }

  .jc-between {
    justify-content: space-between
  }
  .jc-around {
    justify-content: space-around;
  }
  .jc-center {
    justify-content: center;
  }
  .m-center {
    margin: 0 auto;
  }
}



.shadow-dark {
  box-shadow: 0 0 2rem #302e4d26;
}


.hidden {
  display: none !important;
}


// Home
@import './navigation';
@import './lightbox';
@import './style-switcher';

@import './home';
@import './about';
@import './services';
@import './portfolio';
@import './blog';
@import './contact';

@import '../../skins/dark-mode';



@include tabwide {
  
  .site-container {
    padding-left: 0;
  }

  .page-section {
    left: 0;
  }

  .nav__side-drawer {
    display: flex;
  }

  // .nav__side-drawer.opened-drawer ~ .site-content .page-section.active-page {
  //   transform: translateX(20rem);
  // }

  .side-nav {
    transform: translateX(-100%);
  }
}



