// DARK MODE

/*

$lskin-bg-dark: #f2f2fc;
$lskin-bg-light: #fdf9ff;
$lskin-heading: #302e4d;
$lskin-text: #504e70;
$lskin-border-dark: #d4d4e3;
$lskin-border-light: #e8dfec;
*/

$ds-bg-dark: #151515;
$ds-bg-light: #222222;
$ds-heading: #ffffff;
$ds-text: #e9e9e9;
$ds-border: #393939;

.site-container.dark-mode {
  background-color: $ds-bg-dark;

  .shadow-dark {
    box-shadow: none;
  }
  
  .page-section{
    background-color: $ds-bg-dark;
  }

  .side-nav {
    background-color: $ds-bg-light;
    border-color: $ds-border;
    
    a {
      color: $ds-heading;
      border-color: $ds-border;
    }

    .copyright-text {
      color: $ds-text;
    }
  }

  h1,h2,h3,h4,h5,h6 {
    color: $ds-heading;
  }
  p {
    color: $ds-text;
  }
  a {
    color: $ds-text;
  }

  .about {
    span {
      color: $ds-text;
    }

    .info-item {
      border-color: $ds-border;
    }


    .about-details,
    .about-text {


      h5 {
        color: $ds-heading;
      }
    }
  }

  .timeline {
    background-color: $ds-bg-light;
    border-color: $ds-border;

    .timeline-title {
      color: $ds-heading;
    }
    .timeline-text {
      color: $ds-text;
    }
  }

  .services {

    .service-item-inner {
      background-color: $ds-bg-light;
      border-color: $ds-border;
    }

  }



  .portfolio {

    .portfolio-item-inner {
      border-color: $ds-border;
      background-color: $ds-bg-light;
    }

    .portfolio-filter{
      button {
        color: $ds-heading;
      }
    }
  }

  .blog {

    .blog-item-inner {
      border-color: $ds-border;
    }

    .blog-info {
      background-color: $ds-bg-light;
    }

    .blog-tags {
      color: $ds-text;
    }
  }


  .contact {
    .contact-form {

      .form-control {
        background-color: $ds-bg-light;
        border-color: $ds-border;
        color: $ds-text;
      }

      button:disabled {
        background-color: #222222 !important;
        color: #555555;
      }
    }


  }


  .music-container,
  .progress-container,
  .music-info,
  .action-btn,
  .cover-imgbox::after {
    background-color: lighten($ds-bg-light, 3%);
  }

  .cover-imgbox {
    border: $ds-border;
  }

}