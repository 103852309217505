@import './variables';
@import './colors';
@import './mixins';


*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  @include box-sizing(inherit);
}

html,
body
.site-container {
  width: 100%;
  height: 100%;
}


html {
  font-size: 62.5%;
  overflow-y: scroll;
  @include box-sizing(border-box);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
}

input,
button,
textarea {
  border-radius: 0;
  -webkit-appearance: none;
  outline: none;
}


.site-bg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: $site-bg-gradient;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

select {
  -webkit-appearance: none;
  appearance: none;
  background-color: black;
  border-color: color(gray3);
  color: #ddd;

  padding: .6rem 1.4rem;
  font-size: 1rem;
}

.img-box img{
  max-width: 100%;
}

.vh-quarter {
  min-height: 30vh;
}

.vh-half {
  min-height: 60vh;
}

.vh-full {
  min-height: 100vh;
}

.vh-double {
  min-height: 200vh;
}

@import './typography';
@import './animations';
@import './containers';
