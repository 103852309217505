.services {

  .container {
    padding-bottom: 4rem;
  }
}

.service-item {
  flex: 0 1 40rem;
  margin: 0 auto;
  margin-bottom: 3rem;
  cursor: pointer;
  display: flex;

  .service-item-inner {
    background-color: $lskin-bg-light;
    border: 1px solid $lskin-border-dark;
    border-radius: 1rem;
    padding: 3rem 1.5rem;
    text-align: center;
    overflow: hidden;
    transition: box-shadow .3s ease;
  }

  .icon {
    height: 6rem;
    width: 6rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 2rem;
    transition: all 0.3s ease;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transform: scale(0);
      transition: transform .8s .4s ease;
    }

    svg {
      width: 2.8rem;
      height: 2.8rem;
      fill: white;
      z-index: 0;
      transition: fill .3s .5s ease;
    }
  }

  h4 {
    position: relative;
    font-size: 2rem;
    margin-bottom: 1rem;
    transition: color .3s .5s ease;
    font-weight: bold;
  }

  p {
    position: relative;
    line-height: 25px;
    transition: color .3s .5s ease;
  }

  &:hover {
    .icon::before {
      transform: scale(12);
      opacity: 1;
    }

    .service-item-inner {
      box-shadow: .5rem .5rem 1rem #302e4d40;
    }

    h4 {
      color: #ffffff;
    }

    p {
      color: #eeeeee;
    }
  }

}