.music-container {
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 2rem 2rem 0 rgba($skin-pink, .3);
  
  padding: 1rem 2rem 1rem 0;
  left: 1rem;
  bottom: 1.5rem;
  position: fixed;
  
  width: 100%;
  max-width: 35rem;
  display: flex;
  align-items: center;

  z-index: 100;
}

.cover-imgbox {
  width: 10rem;
  height: 10rem;
  border: 1px solid $lskin-border-dark;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: -50%;
  z-index: 100;
  
  img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    animation: rotate 3s linear infinite;
    animation-play-state: paused;
  }
}

.cover-imgbox::after {
  content: '';
  width: 10%;
  height: 10%;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #0003;
}



.music-container.play .cover-imgbox img {
  animation-play-state: running;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  width: 100%;
  max-width: 20rem;
  margin-left: auto;
  margin-right: 0rem;
}

.action-btn {
  background-color: white;
  border: none;
  color: #dfdbdf;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  margin: 0 20px;
}

.action-btn.action-btn-big {
  color: #cdc2d0;
  font-size: 30px;
}

.action-btn:focus {
  outline: none;
}

.music-info {
  background-color: #fff;
  border-radius: 1.5rem 1.5rem 0 0;
  position: absolute;
  top: 0;
  left: 20px;
  width: calc(100% - 40px);
  padding: 1rem;
  text-align: right;
  opacity: 0;
  transform: translateY(0%);
  transition: transform 0.3s ease-in, opacity 0.3s ease-in;
  z-index: 0;
}

.music-container.play .music-info {
  opacity: 1;
  transform: translateY(-100%);
}

.music-info h4 {
  margin: 0;
}

.progress-container {
  background: white;
  border-radius: 5px;
  cursor: pointer;
  margin: 1rem 0;
  margin-left: auto;
  height: 5px;
  width: 70%;
  border: 1px solid $skin-pink;
}

.progress {
  background-color: $skin-pink;
  border-radius: 5px;
  height: 100%;
  width: 0%;
  transition: width 0.1s linear;
}