


.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  .flex-item {
    margin: 1rem;
  }
}

.size-fullpage{
  width: 100%;
  height: 100vh;
}

.size-fullbanner{
  width: 100%;
  height: 90vh;
}

.img-cover {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: bottom bottom;
  background-color: #111;
}

.p-2-1 {
  padding: 2rem 1rem;
}
.p-2-2 {
  padding: 2rem 2rem;
}
.p-2-4 {
  padding: 2rem 4rem;
} 





