.about {

  .about-content {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .about-text {
    flex: 0 0 100%;

    h3 {
      font-size: 2.4rem;
      margin-bottom: 1.5rem;
      font-weight: 700;
    }

    p {
      line-height: 25px;
    }
  }

  .about-details {
    justify-content: space-around !important;
  }

  .personal-info {
    display: flex;
    flex: 0 1 55rem;
    margin-top: 4rem;
    // border: 2px solid red;
  }

  .info-item {
    flex: 1 1 30rem;
    max-width: 40rem;

    display: flex;
    align-items: center;
    border-bottom: 1px solid $lskin-border-light;

    @include tabwide {
      margin: 0 auto;
    }

    @include flip {
      flex-wrap: wrap;
    }

    p {
      flex: 1 0 10rem;
      max-width: 15rem;

      font-weight: 600;
      padding: 1rem 0;
      color: $lskin-heading;
    }

    span {
      flex: 1 1 20rem;

      text-align: left;
      font-weight: 400;
      color: $lskin-text;
      display: inline-block;
      font-size: 1.6rem;
    }
  }

  .buttons {
    margin: 0 auto;
    margin-top: 3rem;
    button {
      margin-bottom: 1.4rem;
    }
  }
}


.skills {
  // background-color: blue;
  flex: 1 1 30rem;
  margin: 0 auto;
  margin-top: 4rem;
  max-width: 40rem;
  padding: 0 2rem;
  .row {
    flex-direction: column;
  }
}

.skill-item {
  margin-bottom: 1rem;

  h5 {
    line-height: 30px;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: capitalize;
  }

  .progress {
    background-color: #e3e3e3;
    height: 8px;
    border-radius: .4rem;
    width: 100%;
    position: relative;
  }

  .progress-in {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: .4rem;
  }

  .skill-percent {
    position: absolute;
    right: 0;
    top: -2.5rem;
    font-weight: 400;
  }
}

.about-content {
  h3.title {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 3rem;
  }

  .education,
  .experience {
    flex: 1 1 50rem;
    max-width: 60rem;

    margin: 0 auto;
    margin-top: 3rem;
  }


  .timeline-box {
    flex: 0 0 100%;
  }

  .timeline {
    background-color: $lskin-bg-light;
    padding: 3rem 1.5rem;
    border: 1px solid $lskin-border-dark;
    border-radius: 1rem;
    width: 95%;
    margin: 0 auto;
  }
}

.timeline-item {
  position: relative;
  padding-left: 3.7rem;
  padding-bottom: 5rem;

  &:last-child {
    padding-bottom: 0;
  }

  &::before {
    content: '';
    width: 1px;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    background-color: gray;
    
    left: .7rem;
  }

  .circle-dot {
    position: absolute;
    left: 0;
    top: 0;
    height: 1.5rem;
    width: 1.5rem;
    background-color: gray;
    border-radius: 50%;
  }

  .timeline-date {
    font-size: 1.4rem;
    font-weight: 400;
    color: $lskin-text;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    svg {
      margin-bottom: .2rem;
      margin-right: .5rem;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  .timeline-title {
    font-weight: 600;
    font-size: 1.8rem;
    text-transform: capitalize;
    color: $lskin-heading;
    margin-bottom: .8rem;
  }
  .timeline-text {
    font-size: 1.6rem;
  }
}