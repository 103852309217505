.blog {
  .container {
    // 7 - 3rem
    padding-bottom: 4rem;
  }

  .blog-item-list {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(45rem, 1fr)
    );
    margin-right: -1.5rem;
    margin-left: -1.5rem;
    position: relative;

    @include mobile {
      grid-template-columns: 1fr;
    }
  }
}

.blog-item {
  max-width: 50rem;
  margin: 0 auto;
  margin-bottom: 3rem;
  display: flex;
}

.blog-item-inner {
  border-radius: .8rem;
  overflow: hidden;
  border: 1px solid $lskin-border-dark;

  .blog-img {
    width: 100%;
    position: relative;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
      transition: all 0.5s ease;
    }

    .blog-date {
      position: absolute;
      padding: .4rem 1rem;
      border-radius: .5rem;
      font-weight: 600;
      font-size: 1.4rem;
      left: 1.5rem;
      bottom: 1.5rem;
    }
  }

  .blog-info {
    padding: 3rem 1.5rem;
    background-color: $lskin-bg-light;
    height: 100%;
  }

  .blog-title {
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 1rem;
  }

  .blog-description {
    line-height: 25px;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }

  .blog-tags {
    font-size: 1.6rem;
    line-height: 25px;
    color: $lskin-text;
    font-weight: 400;
    font-size: 1.4rem;
    text-transform: capitalize;

    .tag-link {
      font-weight: 600;
    }
  }

  &:hover {
    .blog-img img {
      transform: scale(1.1);
    }
  }
}

