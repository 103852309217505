.home {

  .intro {
    text-align: center;

    h1 {
      font-size: 4rem;
      font-family: $font-title;
      font-weight: lighter;
      margin: 2rem 0 .5rem;
    }

    p {
      font-size: 2rem;
      font-weight: 500;
      margin: 0;
      line-height: 22px;
    }
  }

  .intro__img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    border: 8px solid #ffffff;

    img {
      @include img-cover;
    }
  }

  .social-links {
    display: inline-flex;
    margin:  0 auto;
    margin-top: 2.5rem;

    a {
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      background-color: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 .5rem;
      overflow: hidden;
      transition: all 0.3s ease;

      svg {
        color: white;
        height: 1.8rem;
        width: 1.8rem;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }

}

