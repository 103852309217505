@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

$wide: 1200px;
$desktop: 1024px;

$tabwide: 900px;
$tablet: 768px;

$mobile: 480px;
$flip: 360px;

@mixin wide {
  @media only screen and (min-width: #{$wide}) {
      @content;
  }
}

@mixin desktop {
  @media only screen and (max-width: #{$desktop}) {
      @content;
  }
}

@mixin tabwide {
  @media only screen and (max-width: #{$tabwide}) {
      @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: #{$tablet}) {
      @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: #{$mobile}) {
      @content;
  }
}

@mixin flip {
  @media only screen and (max-width: #{$flip}) {
      @content;
  }
}

@mixin img-cover($pos: top) {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center $pos;
}

@mixin button ($color) {
  background-color: $color;
  padding: .5rem 1rem;
  display: block;
  text-align: center;
  color: $white;
  text-decoration: none;
  border: none;
  width: 100%;
  border-radius: 5px;
  font-size: .8rem;
  padding: 1rem 0;
}

@mixin no-select {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

@mixin test {
  background-color: #ededed;
}

@mixin box-sizing($box-model) {
  box-sizing: $box-model;
}
@mixin gradient($color1, $color2) {
  background: linear-gradient(to right, $color1 0%, $color2 100%);
}
