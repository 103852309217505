.alerts {
  position: fixed;
  top: 6rem;
  right: 0;
  z-index: 90;
  overflow: hidden;
}

.alerts-item {
  width: calc(100vw - 1rem);
  max-width: 30rem;
  box-shadow: 2px 1px 8px #0004;
  padding: 1rem 1rem .5rem;
  margin-bottom: .6rem;
  margin-right: .5rem;
  transform: translateX(100%);
  animation: alert-pop 4s ease forwards;
  
  cursor: pointer;
  background-color: #3776ac;
  &:hover {
    background-color: #5485af;
  }

  .alerts-title {
    color: #eee
  }

  .alerts-message {
    color: #ccc;
    font-size: 1.3rem;
    padding: .6rem 0;
  }
}

.alerts-item {

  &.--danger {
    background-color: #d03d3d;
    &:hover {
      background-color: #db5959;
    }
  }

  &.--message {
    background-color: #2ba042;
    &:hover {
      background-color: #6fdb59;
    }
  }
}

@keyframes alert-pop {
  0% {
    transform: translateX(100%);
  }

  25% {
    transform: translateX(0);
  }

  75% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}