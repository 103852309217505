$main_font : 'Quicksand', sans-serif;

$header-height: 5rem;
$site-bg-gradient: linear-gradient(135deg,
#24405666 0%,
#0d253566 100%);

$site-bg-gradient-2: linear-gradient(
  45deg,
  #385170e0 0%,
  #142d4ce0 100%
);

$site-bg-gradient-3: linear-gradient(
  45deg, 
  #243949 0%,
  #517fa4 100%
);

$container-inshadow: inset 2px 6px 20px 5px #0008;
$container-shadow: 1px 1px 10px #0004;

$slope-offset: 9rem;




