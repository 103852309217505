@keyframes Show-Item {
  0% {
    opacity: 0;
    transform: scale(.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

