.contact {
  
  .container {
    padding-bottom: 20rem;
  }
}

.contact-info-item {
  flex: 0 0 30rem;
  // max-width: 33.333%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 4rem;

  @include flip {
    flex: 0 0 100%;
  }

  .icon {
    display: inline-block;
    font-size: 3rem;
  }

  h4 {
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: capitalize;
    margin: 1.5rem 0 .5rem;
  }

  p {
    line-height: 2.5rem;
    font-weight: 400;
  }
}

.contact-form {
  // background-color: red;
  flex: 0 0 100%;
  max-width: 100%;

  .col-6 {
    flex: 1 1 30rem;
    // max-width: 50%;
  }

  .col-12 {
    // background-color: red;
    flex: 0 0 100%;
  }


  .form-item {
    margin-bottom: 2rem;

    .form-control {
      width: 100%;
      height: 5rem;
      border-radius: 2rem;
      border: 1px solid $lskin-border-dark;
      padding: 1rem 2.5rem;
      font-size: 1.6rem;
      color: $lskin-text;
      outline: none;
      transition: all 0.3s ease;
    }

    .form-control:focus {
      box-shadow: 0 0 1rem #302e4d26;
    }

    textarea.form-control {
      height: 14rem;
      resize: none;
    }
  }

  button {
    height: 5rem;
    padding: 0 5rem;
    background-color: silver !important;
    text-decoration-line: line-through;
  }
}