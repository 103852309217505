
// Navigation
.side-nav {
  height: 100%;
  width: 27rem;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;

  padding: 3rem;
  background-color: $lskin-bg-light;
  border-right: 1px solid $lskin-border-light;
  transition: transform .6s ease;
  
}

.company-logo {
  padding: 30px 0;
  text-align: center;

  

  a {
    font-size: 3.5rem;
    color: $lskin-heading;
    font-weight: 700;
    position: relative;
    padding: .8rem 1rem;
    line-height: 50px;
    text-transform: capitalize;
  }

  a::after,
  a::before {
    content: '';
    height: 2rem;
    width: 2rem;
    position: absolute;
  }


  a::before {
    top: 0;
    left: 0;
  }

  a::after {
    bottom: 0;
    right: 0;
  }
}




.side-nav__list {
  margin: 8rem 0;

  @include mobile {
    margin-top: 1rem;
  }
}

.side-nav__item {
  display: block;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: .4rem;
    height: 100%;
    background-color: gray;
    top: 0;
    left: 0;
    transition: width .2s .4s ease, transform .4s ease;
    transform: scaleY(0);
  }

  &:hover::before {
    transform: scaleY(1);
    width: 100%;
    z-index: -1;
  }

  &:hover a {
    color: #ffffff !important;
  }



  a {
    display: flex;
    align-items: center;
    padding: 1rem;
    font-size: 1.6rem;
    font-weight: 600;
    border-bottom: 1px solid $lskin-border-light;
    transition: padding 0.3s ease, color .3s .4s ease;
    color: $lskin-text;

    &:not(.active):hover {
      padding-left: 1.5rem;
    }

    svg {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
      margin-bottom: .1rem;
    }
  }
}

.copyright-text {
  font-size: 1.3rem;
  color: #7d7d7d;
  text-align: center;
}




.side-nav__toggler {
  height: 4rem;
  width: 4.5rem;
  border: 1px solid $lskin-border-dark;
  cursor: pointer;
  position: fixed;
  left: 300px;
  top: 30px;
  z-index: 11;
  border-radius: .5rem;
  background-color: $lskin-bg-light;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;

  span {
    height: 2px;
    width: 1.8rem;
    display: inline-block;
    position: relative;

    &::after,
    &::before {
      content: '';
      height: 2px;
      width: 1.8rem;
      position: absolute;
      top: -6px;
      left: 0;
    }
    &::after {
      top: unset;
      bottom: -6px;
    }
  }
}




.nav__side-drawer {
  width: 3.4rem;
  height: 3.2rem;
  position: fixed;
  display: none;

  left: 2.6rem;
  z-index: 1000;

  top: 1rem;

  @include mobile {
    top: 1.2rem;
    left: 1.6rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  .menu {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    transition: transform .5s;

    &-line {
      width: 100%;
      height: .4rem;
      background-color: $skin-pink;
      transform-origin: right;
      transition: all .3s .4s;
      opacity: .9;

      @include mobile {
        height: .3rem;
      }
    }
    
    .menu-line-1 {
      width: 150%;
    }
    .menu-line-3 {
      width: 120%;
    }
  }
}


.nav__side-drawer.opened-drawer .menu {
  transform: rotateZ(90deg) translateX(-10%);
}
.nav__side-drawer.opened-drawer .menu-line {
  opacity: 1;
  background-color: $skin-pink;
}

.nav__side-drawer.opened-drawer .menu-line-1 {
  transform: rotateZ(-45deg) translate(.2rem, -.8rem);
}
.nav__side-drawer.opened-drawer .menu-line-2 {
  opacity: 0;
}
.nav__side-drawer.opened-drawer .menu-line-3 {
  transform: rotateZ(40deg);
}

.nav__side-drawer.opened-drawer ~ .side-nav {
  transform: translateX(0%);
}