.portfolio {

  .container {
    padding-bottom: 4rem;
  }

  .portfolio-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(
      30rem, 1fr
    ));
    margin-right: -1.5rem;
    margin-left: -1.5rem;
    position: relative;

    @include mobile {
      grid-template-columns: 1fr;
    }
  }
}

.portfolio-filter {
  // background-color: red;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  margin-bottom: 4rem;

  button {
    border: none;
    background: transparent;
    font-weight: bold;
    font-size: 1.8rem;
    text-transform: capitalize;
    margin: 1rem;
    color: $lskin-heading;
    cursor: pointer;
    white-space: nowrap;
    padding-bottom: .2rem;
    transition: all 0.3s ease;
    
    outline: none;
    @include no-select;
    -webkit-tap-highlight-color: rgba(#000, 0);
    // border-bottom: 2px solid transparent;
  }

  button.active {
    color: grey;
  }
}

.btn-target {
  position: relative;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    height: 2rem;
    width: 2rem;
  }

  &::before {
    bottom: 0;
    left: 0;
    border-bottom: 2px solid grey;
    border-left: 2px solid gray;
    animation: pulse-bot-left .6s forwards;
  }

  &::after {
    top: 0;
    right: 0;
    border-top: 2px solid gray;
    border-right: 2px solid gray;
    animation: pulse-top-right .6s forwards;
  }
}

@keyframes pulse-bot-left {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  100% {
    opacity: 1;
    transform: translate(-1rem, .6rem);
  }
}

@keyframes pulse-top-right {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  100% {
    opacity: 1;
    transform: translate(1rem, -.6rem);
  }
}

.portfolio-item {
  flex: 0 1 36rem;
  margin-bottom: 3rem;
  animation: Show-Item .4s forwards;
  

  .portfolio-item-inner {
    border: .6rem solid $lskin-bg-light;
    background-color: #ffffff;

    border-radius: 1rem;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }

  .portfolio-img {
    background-color: $lskin-bg-light;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
      border-radius: 1rem;
    }
  }

  .portfolio-info {
    // display: none;
    position: absolute;
    left: 0;
    top: 0;


    
    background: radial-gradient(
      ellipse at center,
      rgba(#000, .4) 0%,
      rgba(#000, .8) 90%
    );

    height: 100%;
    width: 100%;
    z-index: 1;
    padding: 3rem;

    opacity: 0;
    transition: all 0.3s ease;


    h4 {
      font-weight: 700;
      color: #ffffff;
      text-transform: capitalize;

      opacity: 0;
      transform: translateX(-2rem);
      transition: all 0.3s ease;
    }

    .icon {
      width: 4rem;
      height: 4rem;
      background-color: #ffffff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      position: absolute;
      bottom: 3rem;
      right: 3rem;

      opacity: 0;
      transform: translateX(2rem);
      transition: all 0.3s ease;

      cursor: zoom-in;
    }
  }

  &:hover {
    .portfolio-info {
      opacity: 1;
    }

    .portfolio-info h4 {
      opacity: 1;
      transform: translateX(0);
    }

    .portfolio-info .icon {
      opacity: 1;
      transform: translateX(0);
    }
  }

}