// Body - Dark
$dskin-bg-dark: #151515;
$dskin-bg-light: #222222;
$dskin-heading: #ffffff;
$dskin-text: #e9e9e9;
$dskin-border: #393939;

// Body - Light
$lskin-bg-dark: #f2f2fc;
$lskin-bg-light: #fdf9ff;
$lskin-heading: #302e4d;
$lskin-text: #504e70;
$lskin-border-dark: #d4d4e3;
$lskin-border-light: #e8dfec;


$box-shadow: 0 2rem 6rem rgba(0,0,0,.5);



$skin-default: #248dea;


$skin-pink: #ec1839;
$skin-blue: #2196f3;
$skin-orange: #fa5b0f;
$skin-yellow: #ffb400;
$skin-green: #72b626;